import moment from "moment";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from 'axios';
import "../energy-advisor/EAInvitePage.scss";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

class EAInvitePage extends React.Component {
	static propTypes = {
	};

	static defaultProps = {
	};

	constructor() {
		super();

        this.state = {
            editPopupVisible: false,
            inviteData: [],
            inviteProgramAccess: {},
            programNames: {},
            programs: []
        };

        this.refreshInviteList();

        this.bodyClickHandler = this.bodyClickHandler.bind(this);
	}

    bodyClickHandler(e) { // For auto closing the user edit popup on click-out
        e = e || window.event;
        let n = e.target;
        while (n) {
            if (n.className?.indexOf('user-edit-popup') >= 0 || n.className?.indexOf('user-edit-btn') >= 0) {
                break;
            }
            n = n.parentNode;
        }
        if ((n?.className ?? '')?.indexOf('user-edit-popup') < 0 && (n?.className ?? '')?.indexOf('user-edit-btn') < 0) {
            this.setState({ editPopupVisible: false, editUser: null });
        }
    }

    async refreshInviteList() {
        let programNames = {};
        try {
            let resp = await axios.get('/v3/users/invite?statuses=1&statuses=2&statuses=3&statuses=4&statuses=5');
            if (Math.floor(resp.status/100) !== 2) {
                throw "Error retrieving invite list";
            }
            let users = resp.data || [];
            for (let user of users) {
                if (user.token?.programMembership?.length > 0) {
                    user.programAccess = user.token.programMembership;
                }
                else {
                    user.programAccess = [];
                }
            }
            resp = await axios.get('/v3/users');
            if (Math.floor(resp.status/100) !== 2) {
                throw "Error retrieving user list";
            }
            for (let item of (resp.data?.memberships||[])) {
                programNames[item.program_id] = item.program_name;
            }
            for (let user of resp.data?.users) {
                users.push({
                    id: user.id,
                    userEmailAddress: user.email,
                    userRole: user.role_id,
                    invitationStatus: 3,
                    programAccess: resp.data?.memberships?.filter((e) => e.user_id === user.id).map((e) => e.program_id),
                    isUser: true,
                    isMe: this.props.currentUser?.id === user.id
                });
            }
            for (let i=0; i<users.length; i++) {
                if (!users[i].isUser && [3,5].includes(users[i].invitationStatus)) {
                    let dup = users.find((e) => e.userEmailAddress === users[i].userEmailAddress && e.isUser);
                    if (dup) {
                        users.splice(i, 1);
                        i--;
                        continue;
                    }
                    else {
                        users[i].ackError = true;
                    }
                }
            }
            this.setState({ inviteData: users, rand: Math.random() });
        }
        catch (err) {
            console.error('EAInvitePage: Error loading invite list');
            toast.error(this.props.t('errorLoadingInvites'));
        }
        try {
            let resp = await axios.get(`/v3/users/${this.props.currentUser.id}/programs`);
            if (Math.floor(resp.status / 100) !== 2) {
                throw "Error retrieving programs list";
            }
            let programs = [];
            for (let item of resp.data?.programs) {
                const presp = await axios.get(`/v3/programs/${item.programId}`);
                if (Math.floor(presp.status / 100) !== 2) {
                    throw "Error retrieving program info";
                }
                const program = presp.data;
                programs.push({
                    id: item.programId,
                    name: program.program_name
                });
                programNames[item.programId] = program.program_name;
            }
            this.setState({ programs, programNames, rand: Math.random() });
        }
        catch (err) {
            toast.error(this.props.t('errorLoadingPrograms'));
            console.error('EAInvitePage: Error loading programs list');
        }
    }

    async sendInvite(data) {
        try {
            let resp = await axios.post('/v3/users/invite', data);
            if (Math.floor(resp.status/100) !== 2) {
                throw "Error sending invite";
            }
            return true;
        }
        catch (err) {
            const code = err?.response?.status;
            console.error('EAInvitePage: Error sending invite');
            if (code === 409) {
                toast.error(this.props.t('errorSendingInviteDuplicate'));
            }
            else {
                toast.error(this.props.t('errorSendingInvite'));
            }
            return false;
        }
    }

    async resendInvite(invite) {
        try {
            let resp = await axios.post(`/v3/users/invite/${invite.invitationId}/resend`);
            if (Math.floor(resp.status/100) !== 2) {
                throw "error re-sending invite";
            }
            return true;
        }
        catch (err) {
            toast.error(this.props.t('errorResendingInvite'));
            console.error('EAInvitePage: Error resending invite');
            return false;
        }
    }

    async saveEditUser(data) {
        try {
            let resp = await axios.put(`/v3/users/${data.id}`, {
                userRole: data.userRole === 'so' ? 11 : 6
            });
            if (Math.floor(resp.status/100) !== 2) {
                return false;
            }
            let resp2 = await axios.put(`/v3/users/${data.id}/programs`, {
                programIds: data.programAccess
            });
            if (Math.floor(resp2.status/100) !== 2) {
                return false;
            }
            return true;
        }
        catch (err) {
            toast.error(this.props.t('errorUpdatingUser'));
            console.error('EAInvitePage: Error updating user');
            return false;
        }
    }

	componentDidMount() {
        document.body.addEventListener('click', this.bodyClickHandler);
	}

	componentWillUnmount() {
        document.body.removeEventListener('click', this.bodyClickHandler);
	}

	render() {
        const { t } = this.props;
		return (
			<div className="ea-invite-advisor-page">
                <h1>{t('inviteAdvisorToPlatform')}</h1>
                <br/>
                <label for="eaia-email-input">{t('emailAddressToInvite')}:</label>
                <input id="eaia-email-input" />
                <br/>
                <br/>
                <label for="eaia-role-input">{t('userRole')}:</label>
                <select id="eaia-role-input">
                    <option value="ra">{t('energyAdvisor')}</option>
                    <option value="so">{t('serviceOrgManager')}</option>
                </select>
                <br/>
                <br/>
                <label>{t('programAccess')}:</label>
                {this.state.programs.map((program, idx) => <div className='program-access-checkbox' onClick={() => {
                    let epa = this.state.inviteProgramAccess;
                    epa[program.id] = !epa[program.id];
                    this.setState({ inviteProgramAccess: epa });
                }}>
                    <img src={this.state.inviteProgramAccess[program.id] ? '/images/checkbox-checked.png' : '/images/checkbox-unchecked.png'} className='accordion-checkbox' /> {this.state.programNames[program.id]}
                </div>)}
                <br/>
                <br/>
                <button onClick={async () => {
                    let paList = [];
                    for (let key in this.state.inviteProgramAccess) {
                        if (this.state.inviteProgramAccess[key]) {
                            paList.push(key);
                        }
                    }
                    let data = {
                        emailAddress: document.getElementById('eaia-email-input').value.trim(),
                        userRole: document.getElementById('eaia-role-input').value.trim(),
                        programIds: paList
                    };
                    if (!data.emailAddress.length || !data.userRole.length) {
                        toast.error(t('fillInAllFields'));
                    }
                    else {
                        if (await this.sendInvite(data)) {
                            document.getElementById('eaia-email-input').value = '';
                            document.getElementById('eaia-role-input').value = '';
                            this.setState({ inviteProgramAccess: {} });
                            this.refreshInviteList();
                            toast.success(t('inviteSent'));
                        }
                    }
                }}>{t('sendInvite')}</button>
                <br/>
                <br/>
                <hr/>
                <h1>{t('usersAndInvites')}</h1>
                <table>
                    <tr>
                        <th>{t('emailAddress')}</th>
                        <th>{t('role')}</th>
                        <th>{t('status')}</th>
                        <th>{t('programAccess')}</th>
                        <th>{t('inviteActions')}</th>
                    </tr>
                    {this.state.inviteData.map((invite) => (<tr className={invite.ackError ? 'warning-row' : invite.invitationStatus === 4 ? 'error-row' : ''}>
                        <td>{invite.userEmailAddress}</td>
                        <td>{{6:t("energyAdvisor"), 11:t("serviceOrgManager")}[invite.userRole]}</td>
                        <td>{{"1":t("inviteSent"), "2":t("inviteExpired"),"3":t("signedUp"),"4":t("inviteRejected"),"5":t("signedUp")}[invite.invitationStatus]} {invite.invitationStatus === 1 ? ' - ' + t('expiresOn') : undefined} {invite.invitationStatus === 1 ? ' - ' + moment.unix(parseFloat(invite.expiresOn) / 1000.).format('MMM. DD, YYYY @ HH:mm') : undefined}{invite.ackError ? (' - ' + t('userCreationError')) : ''}</td>
                        <td>{(invite.programAccess??[]).map((e) => this.state.programNames[e] || '???').join(', ')}</td>
                        {invite.isMe ?
                        <td></td> :
                        <td>
                            {!invite.isUser ? 
                            <button onClick={async () => {
                                if (await this.resendInvite(invite)) {
                                    toast.success(t('inviteSent'));
                                }
                            }}>{t('resend')}</button> : 
                            <button className='user-edit-btn' onClick={async () => {
                                let editProgramAccess = {};
                                if (invite?.programAccess) {
                                    for (let programId of invite.programAccess) {
                                        editProgramAccess[programId] = true;
                                    }
                                }
                                this.setState({ editPopupVisible: !this.state.editPopupVisible, editUser: invite, editProgramAccess, editRole: invite.userRole === 1 ? 'ra' : 'so' });
                            }}>{t('edit')}</button>}
                            {(this.state.editPopupVisible && this.state.editUser?.id === invite.id && invite.isUser) ? <div className={"user-edit-popup popup-visible"}>
                                <label>{t('editUser')}</label><br/>
                                <label for="eaia-edit-role-input">{t('userRole')}:</label>
                                <select id="eaia-edit-role-input" onChange={(e) => {
                                    e = e || window.event;
                                    this.setState({ editRole: e.target.value });
                                }} defaultValue={this.state.editRole}>
                                    <option value="ra">{t('energyAdvisor')}</option>
                                    <option value="so">{t('serviceOrgManager')}</option>
                                </select>
                                <br/>
                                <br/>
                                <label>{t('programAccess')}:</label>
                                {this.state.programs.map((program, idx) => <div className='program-access-checkbox' onClick={() => {
                                    let epa = this.state.editProgramAccess;
                                    epa[program.id] = !epa[program.id];
                                    this.setState({ editProgramAccess: epa });
                                }}>
                                    <img src={this.state.editProgramAccess[program.id] ? '/images/checkbox-checked.png' : '/images/checkbox-unchecked.png'} className='accordion-checkbox' /> {program.name}
                                </div>)}
                                <br/>
                                <button onClick={async () => {
                                    let programAccess = [];
                                    for (let key in this.state.editProgramAccess) {
                                        if (this.state.editProgramAccess[key]) {
                                            programAccess.push(key);
                                        }
                                    }
                                    let data = {
                                        id: this.state.editUser.id,
                                        isUser: this.state.editUser.isUser,
                                        userRole: this.state.editRole,
                                        programAccess
                                    };
                                    if (!data.userRole.length) {
                                        toast.error(t('fillInAllFields'));
                                    }
                                    else if (await this.saveEditUser(data)) {
                                        this.refreshInviteList();
                                        this.setState({ editPopupVisible: false });
                                        toast.success(t('userUpdated'));
                                    }
                                }}>{t('save')}</button>
                            </div> : undefined}
                        </td> }
                    </tr>))}
                </table>
			</div >
		);
	}
}


function mapStateToProps(state) {
	return {
        currentUser: state.auth.currentUser
	};
}
export default withTranslation()(withRouter(connect(mapStateToProps)(EAInvitePage)));
