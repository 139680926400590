import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';
import { getHistory } from '../index';
import { AdminRoute, UserRoute, AuthRoute } from './RouteComponents';
import { loginUser } from '../actions/auth';

/* eslint-disable */
import ErrorPage from '../pages/error';
/* eslint-enable */

import '../styles/theme.scss';
import LayoutComponent from '../components/Layout';
// eslint-disable-next-line
import Login from '../pages/auth/login';
import Logout from '../pages/auth/logout';
import ENLogin from '../pages/auth/en-login';
import Verify from '../pages/auth/verify';
import Reset from '../pages/auth/reset';
import Forgot from '../pages/auth/forgot';
import EAAcceptInvitePage from '../pages/energy-advisor/EAAcceptInvitePage';
import { toast } from 'react-toastify';

const CloseButton = ({ closeToast }) => <i onClick={closeToast} className="la la-close notifications-close" />

setInterval(() => {
    if (window.location.href.indexOf("?c7e-feature-flag=") >= 0) {
        const toks = window.location.href.split("?c7e-feature-flag=");
        window.localStorage.setItem('c7e-feature-flag', toks[1]);
        window.UpdateFeatureFlagHeaders();
        window.location.href = toks[0];
        window.location.reload();
    }
}, 250);

class App extends React.PureComponent {

    render() {

        if (window.location.href.indexOf("id_token=") >= 0) {
            window.IS_LOGGING_IN = true;
            const token = window.location.href.split("id_token=")[1];
            this.props.dispatch(loginUser({ token, is_b2c: true })).then(() => {
                window.IS_LOGGING_IN = false;
                window.location.href = "#/app/main";
            });
        } else if (window.location.href.indexOf("&error_description") >= 0) {
            toast.error("Unable to sign in");
            window.location.href = "#/login";
        }

        let IN_ORG_SITE = !!window.ORG_INFO?.org_id;

        return (
            <div>
                <div role='status' aria-atomic='true' aria-live='polite' aria-relevant='additions'>
                    <ToastContainer
                        autoClose={5000}
                        hideProgressBar
                        closeButton={<CloseButton />}
                    />
                </div>
                <ConnectedRouter history={getHistory()}>
                    <HashRouter>
                        <Switch>
                            <Route path="/" exact render={() => <Redirect to="/login" />} />
                            <Route path="/app" exact render={() => <Redirect to="/app/main" />} />
                            <UserRoute path="/app/main" dispatch={this.props.dispatch} component={LayoutComponent} />
                            <AdminRoute path="/admin" currentUser={this.props.currentUser} dispatch={this.props.dispatch} component={LayoutComponent} />
                            <AuthRoute path="/login" exact component={window.location.href.indexOf('energy-navigator') >= 0 ? ENLogin : Login} />
                            <UserRoute path="/logout" dispatch={this.props.dispatch} component={Logout} />
                            <AuthRoute path="/en-login" exact component={ENLogin} />
                            <AuthRoute path="/verify-email" exact component={Verify} />
                            <AuthRoute path="/password-reset" exact component={Reset} />
                            <AuthRoute path="/forgot" exact component={Forgot} />
                            <AuthRoute path="/accept-invitation" component={EAAcceptInvitePage} />
                            <Route path="/error" exact component={ErrorPage} />
                            <Redirect from="*" to="/login" />
                        </Switch>
                    </HashRouter>
                </ConnectedRouter>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    currentUser: store.auth.currentUser,
    loadingInit: store.auth.loadingInit,
});

export default connect(mapStateToProps)(App);
